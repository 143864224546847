import { post, get } from '../../apis';
import AuthRoutes from './Auth.Routes';

const AuthServices = {
  sendOtp: async (obj) => {
    const data = await post(AuthRoutes.sendOtp, obj);
    return data;
  },
  register: async (obj) => {
    const data = await post(AuthRoutes.register, obj);
    return data;
  },
  getUserTypes: async () => {
    const data = await get(AuthRoutes.getUserTypes);
    return data;
  },
  getMembershipPlan: async (id) => {
    const data = await get(AuthRoutes.getMembershipPlan + id);
    return data;
  },
  checkEmailPhone: async (email, phone) => {
    const data = await get(AuthRoutes.checkEmailPhone + email + '/' + phone);
    return data;
  },
  login: async (obj) => {
    const data = await post(AuthRoutes.login, obj);
    return data;
  },
  logout: async () => {
    const data = await post(AuthRoutes.logout);
    return data;
  },
}

export default AuthServices