import { useState } from "react";
import { ErrorToaster } from "../Helpers/toaster/Toaster";
import CommonServices from "../../apis/common/CommonServices";
import Swal from "sweetalert2";

export default function DiscountBanner({ className, type }) {

  const [loading, setLoading] = useState(false);

  // *For Email
  const [email, setEmail] = useState();

  // *For Handle Subscriber
  const handleSubscribe = async () => {
    setLoading(true)
    try {
      if (!email) {
        ErrorToaster('Please enter email')
        return
      }
      const obj = {
        email: email
      }
      const { message } = await CommonServices.addSubscriber(obj)
      Swal.fire({
        title: "Thanks For Subscribe",
        icon: 'success',
        showCancelButton: false,
        confirmButtonColor: `#234386`,
        confirmButtonText: 'OK'
      }).then(async (result) => {
        window.location.reload()
      })
    } catch (error) {
      ErrorToaster(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div
      className={`discount-banner w-full h-[307px] bg-cover flex justify-center items-center ${className || ""
        }`}
      style={{
        background: `url(${process.env.PUBLIC_URL}/assets/images/discount-banner-1.jpg) no-repeat`,
        backgroundSize: "cover",
      }}
    >
      <div>
        <div data-aos="fade-up">
          <h1 className="sm:text-3xl text-xl font-700 text-qblack mb-2 text-center">
            Get <span className="mx-1 text-qyellow">20%</span> Off Discount
            Coupon
          </h1>
          <p className="text-center sm:text-[18px] text-sm font-400">
            by Subscribe our Newsletter
          </p>
        </div>
        <div
          data-aos="fade-right"
          className="sm:w-[543px] w-[300px] h-[54px] flex mt-8"
        >
          <div className="flex-1 bg-white pl-4 flex space-x-2 items-center h-full focus-within:text-qyellow text-qblack">
            <span>
              <svg
                width="17"
                height="15"
                viewBox="0 0 17 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 14H2C1.4 14 1 13.6 1 13V2C1 1.4 1.4 1 2 1H15C15.6 1 16 1.4 16 2V13C16 13.6 15.6 14 15 14Z"
                  stroke="currentColor"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3 4L8.5 8.5L14 4"
                  stroke="currentColor"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            <input
              type="email"
              name="email"
              className="w-full h-full focus:outline-none text-sm placeholder:text-xs placeholder:text-qblack text-qblack font-400 tracking-wider"
              placeholder="EMAIL ADDRESS"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <button
            type="button"
            className="sm:w-[158px] w-[80px]  h-full bg-qblue text-qwhitetext text-sm font-600"
            onClick={() => handleSubscribe()}
            disabled={loading}
          >
            {/* Get the Coupon */}
            Subscriber
          </button>
        </div>
      </div>

    </div>
  );
}
