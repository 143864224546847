import React, { useEffect, useRef, useState } from 'react'
import { Link } from "react-router-dom";
import ProductCardStyleOne from "../Helpers/Cards/ProductCardStyleOne";
import { AttachFile, Close, QuestionAnswer, SendOutlined } from "@mui/icons-material";
import { Box, CircularProgress, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import moment from 'moment';
import ChatServices from '../../apis/chat/ChatServices';
import { useForm } from 'react-hook-form';
import { useAuth } from '../../context/UseContext';
import { ErrorToaster } from '../Helpers/toaster/Toaster';

export default function SallerInfo({ sellerId, shopDetail, products }) {

  const { user } = useAuth();
  const fileInputRef = useRef(null);

  const { register, handleSubmit, reset } = useForm();
  const [loading, setLoading] = useState(false);

  const [chatScreen, setChatScreen] = useState(false);

  const [conversation, setConversation] = useState([]);
  const [conversationId, setConversationId] = useState();

  const [uploadedFile, setUploadedFile] = useState();
  const [previewFile, setPreviewFile] = useState();
  const [imgBaseUrl, setImgBaseUrl] = useState();

  // *For Get All Conversation and Filter the Admin
  const getConversations = async () => {
    try {
      const { data } = await ChatServices.getConversations()
      const adminConversationId = data.find(e => e.opposite_user?.id == sellerId)?.id
      setConversationId(adminConversationId)
    } catch (error) {
      ErrorToaster(error)
    }
  }

  // *For Get Conversations By Id
  const getConversationMessages = async () => {
    try {
      const { imageBaseUrl, data } = await ChatServices.getConversationMessages(conversationId)
      setImgBaseUrl(imageBaseUrl)
      const messages = []
      data?.forEach(e => {
        let obj = {
          conversationId: e?.conversation_id,
          date: e?.created_at,
          message: e?.message,
          attachment: e?.attachment,
          userId: e?.user_id
        }
        messages.push(obj)
      })
      setConversation(messages.reverse())
    } catch (error) {
      console.log("🚀 ~ file: index.js:23 ~ getConversations ~ error:", error)
    }
  }

  // *For Send Message
  const createConversation = async (data) => {
    setLoading(true)
    try {
      const formData = new FormData()
      formData.append('message', data.message)
      if (uploadedFile) {
        formData.append('attachment', uploadedFile)
      }
      if (conversation.length > 0) {
        await ChatServices.sendMessage(conversationId, formData)
      } else {
        formData.append('receiver', sellerId)
        await ChatServices.createConversation(formData)
      }
    } catch (error) {
      ErrorToaster(error)
    } finally {
      setLoading(false);
      reset()
      getConversationMessages()
      setUploadedFile()
      setPreviewFile()
    }
  }

  useEffect(() => {
    if (chatScreen) {
      getConversationMessages()
    }
  }, [chatScreen]);

  useEffect(() => {
    if (user?.token) {
      getConversations()
    }
  }, [user]);

  return (
    <div className="saller-info-wrapper w-full">
      <div className="saller-info sm:flex justify-between items-center pb-[30px] border-b border-[#E8E8E8]">
        <div className="sm:flex sm:space-x-5 items-center sm:w-1/4">
          <div className="saller w-[85px] h-[85px] rounded-full overflow-hidden">
            <Link to={`/seller-page/${sellerId}`}>
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/saller-1.png`}
                alt="saller"
                className="w-full h-full object-contain"
              />
            </Link>
          </div>
          <div>
            <h6 className="text-[18px] font-medium leading-[30px]">
              {shopDetail?.shop_name}
            </h6>
            <p className="text-[13px] font-normal text-qgray leading-[30px]">
              seller: {shopDetail?.seller_name}
            </p>
            <p className="text-[13px] font-normal text-qgray leading-[30px]">
              {shopDetail?.shop_city_name}, {shopDetail?.shop_country_name}
            </p>
            <p className="text-[14px] font-medium text-[#136cff] leading-[30px] cursor-pointer"
              onClick={() => setChatScreen(true)}
            >
              <QuestionAnswer sx={{ fontSize: '18px' }} /> Chat Now
            </p>
            {chatScreen &&
              <Box
                sx={{
                  position: 'fixed',
                  right: '15px',
                  bottom: '25px',
                  zIndex: '9999',
                }}
              >
                <Box
                  sx={{
                    width: '350px',
                    height: 'calc(100vh - 100px)',
                    borderRadius: '6px',
                    bgcolor: '#fff',
                    boxShadow: '0 4px 20px 0 rgba(0,7,22,.12)'
                  }}
                >
                  <Box sx={{ bgcolor: '#234386', borderTopLeftRadius: '6px', borderTopRightRadius: '6px', p: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h6" color="white">
                      {shopDetail?.shop_name}
                    </Typography>
                    <IconButton
                      onClick={() => setChatScreen(false)}
                      sx={{
                        color: '#fff',
                      }}
                    >
                      <Close />
                    </IconButton>
                  </Box>
                  <Box sx={{ p: 1, height: 'calc(100vh - 215px)', overflow: 'auto', display: 'flex', flexDirection: 'column-reverse' }}>
                    {conversation.map((item, index) => (
                      <Box key={index} sx={{ maxWidth: '95%', my: 1, width: 'fit-content', ml: user?.id === item?.userId && 'auto', mr: user?.id !== item?.userId && 'auto' }}>
                        <Typography component={'p'} variant="caption" sx={{ textAlign: user?.id === item?.userId ? 'left' : 'right', color: '#323B4B' }}>
                          {moment(item.created_at).format('DD.MM.YYYY HH:mm a')}
                        </Typography>
                        <Box sx={{ borderRadius: '4px', bgcolor: '#d9d9d9', p: 1.5 }}>
                          {item?.attachment &&
                            <Box
                              component={'img'}
                              src={imgBaseUrl + item?.attachment}
                              sx={{ maxWidth: '250px', height: 'auto', objectFit: 'contain' }}
                            />
                          }
                          <Typography variant="body2" sx={{ color: '#323B4B' }}>
                            {item.message}
                          </Typography>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                  <Box component="form" onSubmit={handleSubmit(createConversation)} sx={{ borderBottomLeftRadius: '6px', borderBottomRightRadius: '6px', p: 1, borderTop: '1px solid #b6bec9' }}>
                    {previewFile &&
                      <Box sx={{ position: 'relative' }}>
                        <Box sx={{ position: 'absolute', left: '0px', bottom: '0px', width: '100%', height: 'calc(100vh - 210px)', bgcolor: '#d9d9d9' }}>
                          <IconButton color="primary" onClick={() => { setPreviewFile(); setUploadedFile() }} sx={{ position: 'absolute', right: 10, top: 10 }}>
                            <Close sx={{ width: '16px', height: '16px', color: '#FF2121' }} />
                          </IconButton>
                          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
                            <Box
                              component={'img'}
                              src={previewFile}
                              sx={{ maxWidth: '310px', maxHeight: '310px', objectFit: 'contain' }}
                            />
                          </Box>
                        </Box>
                      </Box>
                    }
                    <TextField
                      fullWidth
                      size="small"
                      placeholder={'Ask a question'}
                      {...register("message")}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton component="label" edge="start" onClick={() => fileInputRef.current.click()}>
                              <AttachFile color="primary" />
                            </IconButton>
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton type='submit' edge="end" >
                              {loading ? (
                                <CircularProgress size={20} sx={{ color: '#234386' }} />
                              ) : (
                                <SendOutlined sx={{ color: '#234386' }} />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            }
            {/* <div className="flex items-center mt-4">
              <div className="flex">
                <Star w="15" h="15" />
                <Star w="15" h="15" />
                <Star w="15" h="15" />
                <Star w="15" h="15" />
                <Star w="15" h="15" />
              </div>
              <span className="text-[13px] font-normal ml-1">(4.5)</span>
            </div> */}
          </div>
        </div>
        {/* <div className="flex-1 w-full sm:flex sm:space-x-5 justify-between sm:ml-[60px] mt-5 sm:mt-0">
          <div className="w-full mb-5 sm:mb-0">
            <ul>
              <li className="text-qgray leading-[30px]">
                <span className="text-[15px] font-normal text-qblack">
                  Products
                </span>
                : 120
              </li>
              <li className="text-qgray leading-[30px]">
                <span className="text-[15px] font-normal text-qblack">
                  Category
                </span>
                : Mobile Phone, Sports, Gaming, Electronics
              </li>
              <li className="text-qgray leading-[30px]">
                <span className="text-[15px] font-normal text-qblack">
                  Tags
                </span>
                : Beer, Foamer
              </li>
            </ul>
          </div>
          <div className="w-full ">
            <ul>
              <li className="text-qgray leading-[30px]">
                <span className="text-[15px] font-normal text-qblack">
                  Products
                </span>
                : 120
              </li>
              <li className="text-qgray leading-[30px]">
                <span className="text-[15px] font-normal text-qblack">
                  Category
                </span>
                : Mobile Phone, Sports, Gaming, Electronics
              </li>
              <li className="text-qgray leading-[30px]">
                <span className="text-[15px] font-normal text-qblack">
                  Tags
                </span>
                : Beer, Foamer
              </li>
            </ul>
          </div>
        </div> */}
      </div>
      <div className="saller-product w-full mt-[30px]">
        <h1 className="text-[18px] font-medium mb-5">Product from Shop</h1>
        <div className="grid xl:grid-cols-6 lg:grid-cols-6 sm:grid-cols-3 grid-cols-2 xl:gap-[10px] gap-2">
          {/* <DataIteration
            datas={products}
            startLength={0}
            endLength={products.length}
          >
            {({ datas }) => (
              <div key={datas.id} className="item">
                <ProductCardStyleOne datas={datas} />
              </div>
            )}
          </DataIteration> */}
          {products?.slice(0, 4)?.map((item, index) => (
            <div key={index} className="item">
              <ProductCardStyleOne datas={item} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
