import React, { Fragment, useEffect, useState } from 'react';
import { Box, Dialog, DialogTitle } from '@mui/material';
import OTPInput from 'react-otp-input';
import { ErrorToaster, SuccessToaster } from '../Helpers/toaster/Toaster';
import AuthServices from '../../apis/auth/AuthServices';
import LoaderStyleOne from '../Helpers/Loaders/LoaderStyleOne';

const OtpBox = ({ value, setValue }) => {
  return (
    <Box sx={{ my: 2 }}>
      <OTPInput
        value={value}
        numInputs={5}
        onChange={setValue}
        inputStyle={{
          width: "50px",
          height: "50px",
          fontSize: "26px",
          backgroundColor: '#eaeaea',
          color: "#14212a",
          border: "none",
          borderRadius: '8px',
          outline: "none",
        }}
        focusStyle={{
          width: "50px",
          height: "50px",
          fontSize: "26px",
          outline: "none",
          border: "none",
          borderRadius: '8px',
        }}
        containerStyle={{ display: "flex", justifyContent: "space-evenly" }}
        renderInput={(props) => <input {...props} />}
      />
    </Box>
  )
}

function VerifyOtp({ open, loading, error, otpNumber, register }) {

  const [showButton, setShowButton] = useState(false);

  // *For OTP Value
  const [numberOtp, setNumberOtp] = useState('');

  // *For Resend OTP
  const [resendOtpTimer, setResendOtpTimer] = useState(true);
  const [mins, setMins] = useState();
  const [sec, setSec] = useState();

  // *For Otp Timer
  const otpTimer = () => {
    try {
      const duration = 60 * 2 // *Set OTP Time Duration
      var timer = duration, minutes, seconds;
      var timeInterval = setInterval(function () {
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;
        setMins(minutes)
        setSec(seconds)
        if (--timer < 0) {
          timer = duration;
          setResendOtpTimer(false)
          clearInterval(timeInterval)
        }
      }, 1000);
    } catch (error) {
      ErrorToaster(error)
    }
  }

  // *For Reset OTP Value
  const resetOtpValue = () => {
    setNumberOtp('')
  }

  // *For Resend OTP
  const resendOtp = async () => {
    try {
      let obj = {
        phone_number: otpNumber
      }
      const { message } = await AuthServices.sendOtp(obj)
      SuccessToaster(message)
      otpTimer()
      setResendOtpTimer(true)
    } catch (error) {
      ErrorToaster(error)
    }
  }

  useEffect(() => {
    otpTimer()
  }, []);

  useEffect(() => {
    if (error) {
      resetOtpValue()
    }
  }, [error]);

  useEffect(() => {
    const inputPhoneLength = numberOtp?.length === 5 ? true : false
    if (inputPhoneLength) {
      setShowButton(true)
    } else {
      setShowButton(false)
    }
  }, [numberOtp]);

  return (
    <Dialog
      maxWidth="xs"
      open={open}
      sx={{ '& .MuiDialog-paper': { width: '80%', height: "auto", borderRadius: 2, py: { xs: 2, md: 4 }, px: { xs: 3, md: 6 } } }}
    >
      <DialogTitle sx={{ textAlign: "center", fontSize: '18px', fontWeight: 700 }}>OTP Verification</DialogTitle>
      <div className="mb-5">
        <p className="text-[13px] text-qgraytwo text-center">
          OTP has been sent to your
          preferred whatsapp number
        </p>
        <OtpBox
          value={numberOtp}
          setValue={(val) => setNumberOtp(val)}
        />
        {error &&
          <p className="text-[12px] text-qred text-center">You have entered invalid OTP code</p>
        }
      </div>
      <div className="mb-2">
        {resendOtpTimer ? (
          <p className="text-[13px] font-semibold text-center text-qblue">
            {mins}:{sec}
          </p>
        ) : (
          <Fragment>
            <p className="text-[13px] text-qgraytwo text-center">
              Don’t received OTP code?
            </p>
            <p className="text-[13px] cursor-pointer text-qblue text-center" onClick={() => resendOtp()} >
              Resend Code
            </p>
          </Fragment>
        )}
      </div>
      {showButton &&
        <div className="text-center mt-2">
          <button
            disabled={loading}
            className="text-sm text-white w-full h-[50px] font-semibold flex justify-center bg-qblue items-center"
            onClick={() => register(numberOtp)}
          >
            <span>Verify</span>
            {loading && (
              <span className="w-5 " style={{ transform: "scale(0.3)" }}>
                <LoaderStyleOne />
              </span>
            )}
          </button>
        </div>
      }
    </Dialog>
  );
}

export default VerifyOtp;