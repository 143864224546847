const AuthRoutes = {
  sendOtp: 'send_otp',
  register: 'register',
  getUserTypes: 'getUserTypes',
  getMembershipPlan: 'getMemberShipPlanByUserTypeId/',
  checkEmailPhone: 'checkEmailAndPassword/',
  login: 'login',
  logout: 'logout',
};

export default AuthRoutes